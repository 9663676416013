// src/app/services/kendra.service.ts

import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AWS from 'aws-sdk';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class KendraService {

  private token: any = localStorage.getItem('token');
  inputValue = '';
  selectedValue: any;
  
  private apiUrl = 'https://pvv4at2hwk.execute-api.us-east-1.amazonaws.com/dev/possiblequestions'; // Replace with your POST API URL
  private apiUrlSearch = 'https://hxhtio0e10.execute-api.us-east-1.amazonaws.com/dev/compliance-search';
  private apiUrlDeviation = 'https://y2c7tlqlmg.execute-api.us-east-1.amazonaws.com/dev/control-deviation';  
  
  constructor(private http: HttpClient) {}

  @ViewChild('dropdown') dropdown!: ElementRef;

  getSelectedValue() {
    this.selectedValue = this.dropdown.nativeElement.value;
    console.log('Selected Value:', this.selectedValue);
    // You can perform additional actions here
  }

  /*constructor() {
    // Initialize the Amazon Kendra client with region and credentials (if needed)
    this.kendra = new AWS.Kendra({
      region: 'us-east-1', // Replace with your desired region
      accessKeyId: '',     // Leave empty if no authentication is required
      secretAccessKey: ''  // Leave empty if no authentication is required
    });
  }*/
  
  search(queryText: string): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',      
    });    
    
  const body = JSON.stringify({    
      "documenturi": "https://genpact-document-complaince-poc.s3.amazon.com/poc-originaldocuments/msa/20170704_msa_osa_Facebook.pdf",
      "ClientID": queryText   
    });

    /*const body = JSON.stringify({
      "documenturi":"https://genpact-document-complaince-poc.s3.amazon.com/poc-originaldocuments/msa/20170704_msa_osa_Facebook.pdf",
      "standard_questions":[
          "How does the business connect to client network ?",
          "Which Genpact end point image this business uses?",
          "Does this business work in office or works from Home?"
      ]      
    });*/
    
    return this.http.post(this.apiUrl, body, { headers }).toPromise();
  }

  getDataAsPromisePost(url: string, data: any = {}, dropdown: any = {},  headers: any = {}): Promise<any> {
    
    headers = {          
      'Content-Type': "application/json"      
      //'Authorization': this.token      
    };

    const body = JSON.stringify({      
      "client_name":"Client_" + dropdown,
        "question": data            
    });

    return new Promise((resolve, reject) => {
        fetch(url, { 
            method: 'POST', 
            headers,
            body: body
        })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }

  searchAPI(query: string, dropdown: string) {
    const promise = this.getDataAsPromisePost(`${this.apiUrlSearch}`, query, dropdown, { method: 'POST',mode :'no-cors'}); // Calling your method that returns a Promise
    return from(promise);
  }

  getDataAsPromiseDeviation(url: string, data: any = {}, dropdown: any = {},  headers: any = {}): Promise<any> {
    
    headers = {          
      'Content-Type': "application/json"      
      //'Authorization': this.token      
    };

    const body = JSON.stringify({      
      "client_name":"Client_" + dropdown,
        "question": data            
    });

    return new Promise((resolve, reject) => {
        fetch(url, { 
            method: 'POST', 
            headers,
            body: body
        })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }

  deviationAPI(query: string, dropdown: string) {
    const promise = this.getDataAsPromiseDeviation(`${this.apiUrlDeviation}`, query, dropdown, { method: 'POST',mode :'no-cors'}); // Calling your method that returns a Promise
    return from(promise);
  }

  /*searchAPI(queryText: string): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',      
    });
    
    const body = JSON.stringify({
        "client_name":"Client_Meta",
        "question":
            "How does the business connect to client network ?"          
    });
    
    return this.http.post(this.apiUrlSearch, body, { headers }).toPromise();
  }*/

  /*private kendra: AWS.Kendra;

  constructor() {
    // Initialize the Amazon Kendra client with region and credentials (if needed)
    this.kendra = new AWS.Kendra({
      region: 'us-east-1', // Replace with your desired region
      accessKeyId: '',     // Leave empty if no authentication is required
      secretAccessKey: ''  // Leave empty if no authentication is required
    });
  }

  search(queryText: string): Promise<AWS.Kendra.Types.QueryResult> {
    const params = {
      IndexId: 'your-index-id', // Replace with your index ID
      QueryText: queryText,
    };

    return this.kendra.query(params).promise();
  }*/
}
