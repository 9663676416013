import { MatPaginator } from '@angular/material/paginator';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
// import { PdfChatComponent } from './pdf-chat/pdf-chat.component';
// import { ITBotComponent } from './it-bot/it-bot.component';
// import { TransitionBotComponent } from './Transition-Bot/Transition-Bot.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OktaAuthModule } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';
// import { ConverseAiComponent } from './converse-ai/converse-ai.component';
//import { SopSidenavComponent } from './sop-sidenav/sop-sidenav.component';

import { MatTabsModule } from '@angular/material/tabs';


import { OktaAuth } from '@okta/okta-auth-js';
import { AuthInterceptor } from './auth.interceptor';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { MarkdownModule } from 'ngx-markdown';

import { SecurityContext } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ProfileDropdownComponent } from './shared/components/profile-dropdown/profile-dropdown.component';
import { StopProcessComponent } from './shared/components/stop-process/stop-process.component';
import { UploadFileComponent } from './shared/components/upload-file/upload-file.component';
//import { DataTablesModule } from 'angular-datatables';
import { SocReportComponent } from './soc-report/soc-report.component';
import { BgcComponent } from './bgc/bgc.component';
import { AppFooterComponent } from './app-footer/app-footer.component'
import { BgcListComponent } from './bgc/bgc-list/bgc-list.component';
import { BgcFormComponent } from './bgc/bgc-form/bgc-form.component';
import { BgcInitComponent } from './bgc/bgc-init/bgc-init.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HomeKonverseComponent } from './home-konverse/home-konverse.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MeetingAssistComponent } from './meeting-assist/meeting-assist.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PdfsummaryComponent } from './Document Summarisation/pdfsummary/pdfsummary.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FirstTimeModalComponent } from './shared/components/first-time-modal/first-time-modal.component';
import { LayoutModule } from './shared/components/layout/layout.module';
import { PlatformCheckComponent } from './platform-check/platform-check.component';
import { TeamsauthComponent } from './teamsauth/teamsauth.component';
import { GchatComponent } from './gchat/gchat.component';
import { ChatLayoutComponent } from './shared/components/chat-layout/chat-layout.component';
import { KonverseAIComponent } from './konverse-ai/konverse-ai.component';
import { KonverseAdhocComponent } from './konverse-adhoc/konverse-adhoc.component';
import { KonverseBulkComponent } from './konverse-bulk/konverse-bulk.component';
import { KonverseQuestionsComponent } from './konverse-questions/konverse-questions.component';
import { KonverseitsolutionsComponent } from './konverseitsolutions/konverseitsolutions.component';

const oktaAuth = new OktaAuth(environment.oidc);

@NgModule({
  declarations: [
    AppComponent,


    HomeComponent,
    LoginComponent,
    StopProcessComponent,
    LoaderComponent,
    UploadFileComponent,
    ProfileDropdownComponent,


    UnauthorizedComponent,
    LogoutComponent,
    // ConverseAiComponent,
    SocReportComponent,
    BgcComponent,
    AppFooterComponent,
    BgcListComponent,
    BgcFormComponent,
    BgcInitComponent,
    SidenavComponent,
    HomeKonverseComponent,
    LandingPageComponent,
    MeetingAssistComponent,
    PdfsummaryComponent,
    FirstTimeModalComponent,
    PlatformCheckComponent,
    TeamsauthComponent,
    GchatComponent,
    ChatLayoutComponent,
    KonverseAIComponent,
    KonverseAdhocComponent,
    KonverseBulkComponent,
    KonverseQuestionsComponent,
    KonverseitsolutionsComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    PdfViewerModule,
   // DataTablesModule,
   LayoutModule,
    ModalModule.forRoot(),
    MatTabsModule,
    OktaAuthModule,
    OktaAuthModule.forRoot({ oktaAuth }),

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,

    }), // ToastrModule added


    ModalModule.forRoot(),
    TooltipModule.forRoot(),

    ClipboardModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    }),


    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
     // required animations module
     NgxSpinnerModule.forRoot({
      type : "timer",
       }),


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
