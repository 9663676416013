import { Component, ViewChild, ElementRef } from '@angular/core';
import {FormControl} from '@angular/forms';

// src/app/components/search/search.component.ts
import { KendraService } from '../services/kendra.service';
import { GetclientsService } from '../services/getclients.service';

interface NavigationItem {
  title: string;
  children: NavigationItem[];
  collapsed: boolean; // Add the 'collapsed' property
}

@Component({
  selector: 'app-konverse-adhoc',
  templateUrl: './konverse-adhoc.component.html',
  styleUrls: ['./konverse-adhoc.component.scss']
})
export class KonverseAdhocComponent {

  results: any[] = [];
  questionsAll = "";
  //allClients = "";
  clientItems: any[] = [];
  searchResponse: any[] = [];
  deviationResponse: any[] = [];
  getDocuments: any[] = [];
  getQuestions: any[] = [];
  selectedValue = "";
  selectedValuePossible = "";
  inputValue = '';

  constructor(
    private kendraService: KendraService,
    private GetclientsService: GetclientsService
  ) {}
  

  ngOnInit() {
    this.onGetClients('');
  }

  @ViewChild('dropdown') dropdown!: ElementRef;
  @ViewChild('dropdownpossible') dropdownpossible!: ElementRef;

  getSelectedValue() {
    this.selectedValue = this.dropdown.nativeElement.value;
    console.log('Selected Value:', this.selectedValue);
    // You can perform additional actions here
    this.onGetDocuments(this.selectedValue);
    this.onGetQuestions(this.selectedValue);
  }

  getPossibleSelectedValue() {
    this.selectedValuePossible = this.dropdownpossible.nativeElement.value;
    console.log('Selected Value:', this.selectedValuePossible);
    // You can perform additional actions here
    this.onSearch(this.selectedValuePossible);
  }

  onGetClients(queryText: string) {
    this.GetclientsService.getAllClients(queryText).subscribe({
      next: (data: any) => {     
          const allClients = data.clientName;  
          this.clientItems = [];
          for (let i = 0; i < allClients.length; i++) {
            this.clientItems.push({ customer: allClients[i] },);              
          }          
      },
      error: (error: any) => {
          console.log(error)
      },
      complete: () => {
          console.log('complete')
      }
    });
  }
  
  onGetDocuments(queryText: string) {
    this.GetclientsService.getAllDocuments(queryText).subscribe({
      next: (data: any) => {     
          const getDocumentsData = JSON.parse(data.body);  
          this.getDocuments = [];
          for (let i = 0; i < getDocumentsData.length; i++) {
            this.getDocuments.push({ customer: getDocumentsData[i] },);              
          }
      },
      error: (error: any) => {
          console.log(error)
      },
      complete: () => {
          console.log('complete')
      }
    });
  }

  onGetQuestions(queryText: string) {
    this.GetclientsService.getAllQuestions(queryText).subscribe({
      next: (data: any) => {     
          const getQuestionsData = JSON.parse(data.body);  
          this.getQuestions = [];
          for (let i = 0; i < getQuestionsData.length; i++) {
            this.getQuestions.push({ customer: getQuestionsData[i] },);              
          }
      },
      error: (error: any) => {
          console.log(error)
      },
      complete: () => {
          console.log('complete')
      }
    });
  }

  //onSearch(queryText: string) {
  onSearch(queryText: string) {
    this.kendraService.search(this.selectedValuePossible).then((data) => {
      this.results = data.possiblequestions;
      this.questionsAll = JSON.parse(data.possiblequestions);
      this.showQuestions();
    }).catch((error) => {
      console.error('Error searching:', error);
    });
  }

  adhocSearch(queryText: string) {
    this.searchResponse = [];
    this.kendraService.searchAPI(this.inputValue, this.selectedValue).subscribe({
      next: (data: any) => {     
          console.log(data);
          const searchData = data.body;  
          let confidencescoreData = searchData.confidence_score;
          let conservativeoutputData = searchData.conservative_output;
          let consolidatedoutputData = searchData.consolidated_output;
          let rawresponseData = searchData.raw_response;
          
          let i = 0;
          //for (let i = 0; i < searchData.length; i++) {
            this.searchResponse.push({ number: (i+1), question: this.inputValue, consolidatedoutput: consolidatedoutputData, conservativeoutput: conservativeoutputData, confidencescore: confidencescoreData },);              
            i++;
          //}
          this.deviationSearch('');
      },
      error: (error: any) => {
          console.log(error)
      },
      complete: () => {
          console.log('complete')
      }
    });
  }

  deviationSearch(queryText: string) {
    this.deviationResponse = [];
    this.kendraService.deviationAPI(this.inputValue, this.selectedValue).subscribe({
      next: (data: any) => {     
          console.log(data);
          const devaitionData = JSON.parse(data.body);  
          let iscompliantData = devaitionData.is_compliant;
          let msaoutputData = devaitionData.msa_output;
          let sdoutputData = devaitionData.sd_output;
                    
          let k = 0;
          //for (let i = 0; i < devaitionData.length; i++) {
            this.deviationResponse.push({ number: (k+1), question: this.inputValue, msaoutput: msaoutputData, sdoutput: sdoutputData, iscompliant: iscompliantData },);              
            k++;
          //}
      },
      error: (error: any) => {
          console.log(error)
      },
      complete: () => {
          console.log('complete')
      }
    });
  }

  /*adhocSearch(queryText: string) {
    this.kendraService.searchAPI(queryText).then((data) => {
      this.results = data.possiblequestions;
      this.questionsAll = JSON.parse(data.possiblequestions);
      this.showQuestions();
    }).catch((error) => {
      console.error('Error searching:', error);
    });
  }*/

  /*onGetClients1(queryText: string) {
    this.GetclientsService.getAllClients(queryText).subscribe((data) => {
      console.log(data);
      //this.results = data.possiblequestions;
      //this.questionsAll = JSON.parse(data.possiblequestions);
      //this.showQuestions();
    }, (error: any) => {
      console.log(error)
  });
  }*/


  navigationItems: NavigationItem[] = [
    {
      title: 'Dashboard',
      children: [],
      collapsed: true // Set the initial state
    },
    {
      title: 'IT Compliance',
      children: [
        { title: 'Questions Management', children: [], collapsed: true },
        { title: 'Possible Questions of Documents', children: [], collapsed: true },
        { title: 'Control Deviation Report', children: [], collapsed: true }
      ],
      collapsed: true // Set the initial state
    },
    {
      title: 'History',
      children: [],
      collapsed: true // Set the initial state
    },
    {
      title: 'Audit Logs',
      children: [],
      collapsed: true // Set the initial state
    }
  ];

  toggleItem(item: NavigationItem): void {
    item.collapsed = !item.collapsed;
  }  
  
  items = [
    { number: 1, questions: 'Are devices configured in High Availability mode? Explain the redundant path.', tags: 'Network', action: '' },
    { number: 2, questions: 'Is this a Dedicated or a shared setup?', tags: 'Data', action: '' },
    { number: 3, questions: 'Is a voice solution deployed for this business?', tags: 'Network', action: '' },
    { number: 4, questions: 'What are the patching and vulnerability requirements?', tags: 'Business', action: '' },
    { number: 5, questions: 'How does the business connect to client network?', tags: 'Business', action: '' },
    { number: 6, questions: 'Which Genpact Endpoint image this business uses?', tags: 'Security', action: '' },
    { number: 7, questions: 'Are there any redundancy test requirements?', tags: 'Security', action: '' },
    { number: 8, questions: 'Does this business store any data locally or in genpact? If the data is stored , what is the location of the data stored?', tags: 'Data', action: '' },
    { number: 9, questions: 'Does this business work in office or works from Home?', tags: 'Business', action: '' },
    { number: 10, questions: 'Does this business work on shared network or dedicated network?', tags: 'Data', action: '' },
    { number: 11, questions: 'What are the mandatory security controls on the end points?', tags: 'Network', action: '' }    
  ];

  possibleItems: any[] = [];

  showQuestions() {
    // Add your logic here to display the questions or perform any action
    console.log("Questions button clicked");
    this.possibleItems = [];
    for (let i = 0; i < this.questionsAll.length; i++) {
      this.possibleItems.push({ number: (i+1), questions: this.questionsAll[i], tags: 'Tag' + (i+1), action: '' },);              
    }
  }
}
