<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<app-sidenav ></app-sidenav>

<br>

<!--<nav class="navbar navbar-expand-lg navbar-dark bg-dark shadow" id="app_sidenav"
  style=" background-color:#02132d!important;background-position-y: center;">
  <div class="row center">
    <div class="col-md-6 col-sm-6">
        <span style="font-size: larger;">Konverse IT Solutions</span>
    </div>    
    <div class="col-md-6 col-sm-6">
        <span class="d-flex w-80 align-items-center">
            <select class="custom-select" id="basic">
                <option value="Elaborate" selected>Elaborate</option>
                <option value="Rewrite">Rewrite</option>
                <option value="Concise">Concise</option>
                <option value="MakeMoreReadable">Make More Readable</option>
                <option value="MakeitPlain">Make it plain</option>
                <option value="PassiveActive">Passive to Active</option>
            </select>              
        </span> 
    </div>
  </div>    
</nav>-->
<p class="textalign">Hello, how can I help you ?</p>
<div class="borderbox">    
</div>

<div class="col-md-12 col-sm-12 bottomDiv">     
    <div class="container mt-3">
        <form class="d-flex w-100">
            <input type="text" id="search-input" name="search-input" class="form-control me-2 flex-grow-1" placeholder="Ask a question">                        
            <div class="image-upload">
                <label for="file-input" style="display: inline;">
                    <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
                </label>
                <input id="file-input" type="file" style="display: none;"/>
            </div> 
            <button type='submit' class='btn btn-primary'>Search</button>
        </form> 
    </div>           
  </div>