import { Component, ViewChild, ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as AWS from 'aws-sdk';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GetclientsService {

  private token: any = localStorage.getItem('token');

  //private kendra: AWS.Kendra;
  //private apiUrl = 'https://pvv4at2hwk.execute-api.us-east-1.amazonaws.com/dev/possiblequestions'; // Replace with your POST API URL
  private apiUrl = 'https://ko5n83o20i.execute-api.us-east-1.amazonaws.com/poc/filters/clients';
  private apiUrlPost = 'https://ko5n83o20i.execute-api.us-east-1.amazonaws.com/poc/filters/docs';
  private apiUrlQues = 'https://ko5n83o20i.execute-api.us-east-1.amazonaws.com/poc/questionlib/getQuestion';
  selectedValue: any;
  //private apiUrl = 'https://w37db5w1fb.execute-api.us-east-1.amazonaws.com/poc/filters/docs';
  
  constructor(private http: HttpClient) {}

  @ViewChild('dropdown') dropdown!: ElementRef;

  getSelectedValue() {
    this.selectedValue = this.dropdown.nativeElement.value;
    console.log('Selected Value:', this.selectedValue);
    // You can perform additional actions here
  }

  /*constructor() {
    // Initialize the Amazon Kendra client with region and credentials (if needed)
    this.kendra = new AWS.Kendra({
      region: 'us-east-1', // Replace with your desired region
      accessKeyId: '',     // Leave empty if no authentication is required
      secretAccessKey: ''  // Leave empty if no authentication is required
    });
  }*/

  //token = localStorage['token'];
  //token1 = localStorage.getItem('access_token');

  /*getDataAsPromise1(url: any, data: any = { method: 'GET',mode :'no-cors' }, headers: any = {}): Promise<any> {
    headers = {          
          'Content-Type': "application/json",
          'Authorization': 'eyJraWQiOiItai0ydUR1ak5hVFVpMWxTdklCYm8wY3VONi1LZFNkY19NWWtZT252bTBzIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULkt6LWdTTEF6US1uQ1NlNkd3b1BHdWRldTZZY1JtYUkyOFQyM0ZaazhZWkkiLCJpc3MiOiJodHRwczovL2Rldi04NjgzMDMwLm9rdGEuY29tL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTcyNjc1NTU4MywiZXhwIjoxNzI2NzU5MTgzLCJjaWQiOiIwb2FpcDUwNmhpRDQwcFEwSTVkNyIsInVpZCI6IjAwdWprazVuNGdpWDVzRGYwNWQ3Iiwic2NwIjpbIm9wZW5pZCJdLCJhdXRoX3RpbWUiOjE3MjY3NTQwNDksInN1YiI6InByYXRlZWsuZ2FyZ0BnZW5wYWN0LmNvbSJ9.wTV5Fg8xQ5tGjYVuStGS_Gpg6-OOXksiwxfuFyai-0JR11pAt6q_GiFM0VlFCMzkaA7yYwv59RgLsI4UZWBI9MeiTfZus31tYYvfl2TZhC_048USWwqtpM0oLFk3D9PDKD1I90iJOM2uNzaWswdQ4I67S_zH5WbTnT1SznrYQ0Sxs1hz-Mh2TS6rWCJelwnu2gAGujYVXnCNPDk7LkOS6CTJ7KGRRrDpUmC4yneIHTXAWrnDG_hlSVRV0qVVVXvZuY60qdGYlUYYvSTB5VRzRU5xa03463cEF1ruNJfQT92tjGlIUGCh7WGObPvnFuYpSr9GAzzF6-aauhWUubPWWg'
      //     // 'Authetication': 'Bearer ' + token
      //   //  'Access-Control-Allow-Credentials': true
        };
    return new Promise((resolve, reject) => {
      fetch(url, data)
        .then(response => {
          return resolve(response);
        })
        .catch(error => resolve(error));
    });
  }*/

  getDataAsPromiseQues(url: string, data: any = {}, headers: any = {}): Promise<any> {
    
    headers = {          
      'Content-Type': "application/json",      
      'Authorization': this.token      
    };

    const body = JSON.stringify({      
      "clientName":[
        data
      ]
    });

    return new Promise((resolve, reject) => {
        fetch(url, { 
            method: 'POST', 
            headers,
            body: body
        })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }

  getAllQuestions(query: string) {
    const promise = this.getDataAsPromiseQues(`${this.apiUrlQues}`, query, { method: 'POST'}); // Calling your method that returns a Promise
    return from(promise);
  }

  getDataAsPromisePost(url: string, data: any = {}, headers: any = {}): Promise<any> {
    
    headers = {          
      'Content-Type': "application/json",      
      'Authorization': this.token      
    };

    const body = JSON.stringify({      
      "clientName":[
        data
      ]
    });

    return new Promise((resolve, reject) => {
        fetch(url, { 
            method: 'POST', 
            headers,
            body: body
        })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }

  getAllDocuments(query: string) {
    const promise = this.getDataAsPromisePost(`${this.apiUrlPost}`, query, { method: 'POST'}); // Calling your method that returns a Promise
    return from(promise);
  }

  getDataAsPromise(url:string,data:any={ method: 'GET'}, headers: any = {}): Promise<any> {
    
    headers = {          
      'Content-Type': "application/json",      
      'Authorization': this.token      
    };
      return new Promise((resolve, reject) => {
        fetch(url, { ...data, headers })
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(error => reject(error));
      });
    }

  getAllClients(query: string) {

    const promise = this.getDataAsPromise(`${this.apiUrl}`,{ method: 'GET'}); // Calling your method that returns a Promise
    return from(promise);
 
//     // const apiUrl = 'https://api1.playground.genpact.com/bedrock-chat';
// const apiUrl = 'https://w37db5w1fb.execute-api.us-east-1.amazonaws.com/poc/filters/clients';
 
//     const headers = new Headers({ });
 
//     const requestOptions = {
//       method: 'GET', // Replace with your HTTP method (GET, POST, etc.)
//       headers: headers
//       //body:JSON.stringify({ "query": "how many accounts are there?" }),
//     };
 
//     return new Promise((resolve, reject) => {
//       fetch(apiUrl, requestOptions)
//         .then(response => {
//           // Handle the response here
//           if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//           }
//           resolve(response.json());
//         })
//         .catch(error => {
//           // Handle errors here
//           reject(error);
//         });
//     })
 
  }

  getAllClients1(queryText: string): Promise<any> {
    const headers = new HttpHeaders({      
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*", // or specify your origin
      //"Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      //'Access-Control-Allow-Credentials': 'true',
      //"Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT", // or the methods you support
      //'Authorization': "eyJraWQiOiItai0ydUR1ak5hVFVpMWxTdklCYm8wY3VONi1LZFNkY19NWWtZT252bTBzIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULnJkcmoyUTFsVHFxRDhvcUd6M1FaV2ZSNWJlazBHOXlmU29VUDVRQzR4TFUiLCJpc3MiOiJodHRwczovL2Rldi04NjgzMDMwLm9rdGEuY29tL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTcyNjcyMzU1MiwiZXhwIjoxNzI2NzI3MTUyLCJjaWQiOiIwb2FpcDUwNmhpRDQwcFEwSTVkNyIsInVpZCI6IjAwdWprazVuNGdpWDVzRGYwNWQ3Iiwic2NwIjpbIm9wZW5pZCJdLCJhdXRoX3RpbWUiOjE3MjY3MjM1NDgsInN1YiI6InByYXRlZWsuZ2FyZ0BnZW5wYWN0LmNvbSJ9.GFNCMgE2Kn4WKb56NxJ_0BvnIUjCrKeqm2flp_mwoDt0NTnIWBccbKhjJu4uCSHXbBkGVkzxyFjUzuLNgxIKIsx1IC7PLRz4B2YtTlEh8gxTmqMvvbtcP8CjHiq2z2b4bVcuTFqiU8qFuKcC_THhHeGNIIhDLPPR81tXFVuQrp18UiW_eZT-ZtQfP4z8hVtrxkL__5AU-e1Ktn3X94f1s2O8ooVJR28VFmKg5m56bMz08ZNdfKtZvJvDCpet-MlIp7PUqcuLu5LL_FOMUDRYT1uxvvJDNRo6cLtONfYBcX3XqQuukuw8o51BFaa2UzhpZvzbvV_6dEw0FFUaQMpz6g"
    });
    
    /*const body = JSON.stringify({
      "documenturi":"https://genpact-document-complaince-poc.s3.amazon.com/poc-originaldocuments/msa/20170704_msa_osa_Facebook.pdf",
      "standard_questions":[
          "How does the business connect to client network ?",
          "Which Genpact end point image this business uses?",
          "Does this business work in office or works from Home?"
      ]

      //IndexId: 'your-index-id', // Replace with your index ID
      //QueryText: queryText,
    });*/

    //return this.http.options(this.apiUrl, { headers });
    return this.http.get(this.apiUrl, { headers : headers }).toPromise();
    
    //return this.http.post(this.apiUrl, body, { headers }).toPromise();
  }

  /*private kendra: AWS.Kendra;

  constructor() {
    // Initialize the Amazon Kendra client with region and credentials (if needed)
    this.kendra = new AWS.Kendra({
      region: 'us-east-1', // Replace with your desired region
      accessKeyId: '',     // Leave empty if no authentication is required
      secretAccessKey: ''  // Leave empty if no authentication is required
    });
  }

  search(queryText: string): Promise<AWS.Kendra.Types.QueryResult> {
    const params = {
      IndexId: 'your-index-id', // Replace with your index ID
      QueryText: queryText,
    };

    return this.kendra.query(params).promise();
  }*/
}
