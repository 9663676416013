<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<app-sidenav ></app-sidenav>

<div class="row">
<div class="col-md-2 col-sm-2">
<div class="left-nav">
    <ul>
      <li>
        <a><b>Dashboard</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-questions"><b>Questions Library</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-adhoc"><b>Adhoc</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-bulk"><b>Bulk Upload</b></a>        
      </li>
    </ul>
  </div>
</div>
<div class="col-md-10 col-sm-10">
  <mat-tab-group>
    <mat-tab label="Questions Library">
        <br><br>
        <button type="button" class="btn btn-primary" disabled="disabled">Add Questions</button>
        <br><br>
        <!--<button (click)="showQuestions()">Questions</button>-->
        <table style="margin: 0 auto; width:100%">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Questions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items">
                <td>{{item.number}}</td>
                <td>{{item.questions}}</td>
                <td><i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;&nbsp;{{item.action}}<i class="fa fa-trash-o" aria-hidden="true"></i></td>
              </tr>
            </tbody>
          </table>
          <br><br>
          <div class="col-md-12 col-sm-12">
            <mat-tab-group>
              <mat-tab label="Adhoc">
                <div class="container mt-3">
                  <form class="d-flex w-100">
                      <input type="text" id="search-input" name="search-input" class="form-control me-2 flex-grow-1" placeholder="Ask a question">
                      <button type='submit' class='btn btn-primary'>Search</button>
                  </form> 
                </div>
              </mat-tab>
              <mat-tab label="Bulk Upload">
                <div class="container mt-3">
                  <form class="d-flex w-100 align-items-center">
                      <!-- File Input -->
                      <input type="file" id="file-input" name="file-input" class="form-control me-2 flex-grow-1">
              
                      <!-- Search Button -->
                      <button type='submit' class='btn btn-primary'>Search</button>
                  </form> 
              </div>
              </mat-tab>
            </mat-tab-group>
          </div>
    </mat-tab>
  </mat-tab-group>
</div>
</div>
