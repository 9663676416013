import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, concatMap, finalize, from } from 'rxjs';
import { BgcService } from 'src/app/shared/service/bgc.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { httpRequest } from '@okta/okta-auth-js';


@Component({
  selector: 'app-bgc-form',
  templateUrl: './bgc-form.component.html',
  styleUrls: ['./bgc-form.component.scss']
})
export class BgcFormComponent implements OnInit, OnChanges {

  // File description map for the expected filenames
  fileDescriptionMap: { [key: string]: string } = {
    'MSAChecklist.xlsx': 'Master checklist of Account Switch checks',
    'Population.xlsx': 'Employees list whose background needs to check',
    'Address.xlsx': 'Employees Address checks data',
    'Education.xlsx': 'Employees Education checks data',
    'Employment.xlsx': 'Employees Employment checks data',
    'GenericChecks.xlsx': 'Employees Generic checks data',
    'Consolidation.xlsx': 'Employees consolidation checks data',
    'TYDY.xlsx': 'Employees Tydy checks data',
    'HRSS.xlsx': 'Employees HRSS checks data',
    'MSATracker-NH.xlsx': 'Master checklist of Account Switch checks',
    'Population-NH.xlsx': 'Employee list whose background needs to be checked',
    'Tydy-NH.xlsx': 'Employee Tydy checks data',
    'PrevStageResult.xlsx': 'Previous stage result having HRSS data'
  };
  @Input() placeholder = 'Only following excel file in given template is allowed';
  expectedFilenames: string[] = ['MSAChecklist.xlsx', 'Population.xlsx', 'Address.xlsx', 'Education.xlsx', 'Employment.xlsx', 'GenericChecks.xlsx', 'Consolidation.xlsx', 'HRSS.xlsx', 'TYDY.xlsx', 'MSATracker-NH.xlsx', 'Population-NH.xlsx', 'Tydy-NH.xlsx', 'PrevStageResult.xlsx'];
  selectedFiles: { file: File, success: boolean }[] = [];
  tickstatus: boolean = false;
  filescopy: { file: File, success: boolean }[] = [];

  link: any;
  @Input() bgcData: any;
  @Input() taskname: any;
  // @Input() stage:any;

  baseId: any;
  stageId: any;

  taskNameOption = [
    { id: 'New Hire', name: 'New Hire' },
    { id: 'Internal Movement', name: 'Internal Movement' }
  ];
  fileUploadedStatus: any = [];
  fileurl: any;
  status1: any;

  constructor(
    private bgcService: BgcService,
    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
    private route: ActivatedRoute
  ) {
    const files = this.bgcData?.files || '';
    this.status1 = this.bgcData?.status || '';

    this.fileUploadedStatus = files.split(',');
    // console.log(files);
    // console.log(this.taskname);


  }


  ngOnInit(): void {

    // console.log(this.bgcData);

  }

  getFileDescription(filename: string): string {
    return this.fileDescriptionMap[filename] || 'Description not available';
  }


  getExpectedFilenames(): string[] {


    if (this.taskname === 'New Hire') {

      if (this.stageId === '1') {
        return [
          'MSATracker-NH.xlsx',
          'Population-NH.xlsx',
          'Tydy-NH.xlsx',


        ]
      }

      else {
        return [
          'PrevStageResult.xlsx',
          'MSATracker-NH.xlsx',

        ]


      }
    }



    else {
      return [
        'MSAChecklist.xlsx',
        'Population.xlsx',
        'Address.xlsx',
        'Education.xlsx',
        'Employment.xlsx',
        'GenericChecks.xlsx',
        'Consolidation.xlsx',
        'HRSS.xlsx',
        'TYDY.xlsx'
      ];
    }
  }
  // getBGCDetail() {

  //   this.loaderService.loading.next(true);

  //   this.bgcService.fatchBGC(this.bgcData?.processId).subscribe((response: any) => {
  //     this.loaderService.loading.next(false);
  //     if (response.status == "success") {

  //       ;
  //       //  let selectedName= response.data.taskName;

  //       this.taskname = response.data.taskName;
  //       console.log(this.taskname)
  //     }
  //     else {
  //       this.toastMessage.showError(response.error_message);
  //       //this.router.navigate(['bgc']);
  //     }
  //   }, (error: any) => {

  //     this.toastMessage.showError(error?.error?.error_message);
  //     this.loaderService.loading.next(false);
  //     console.log(error);
  //   });
  // }
  ngOnChanges(changes: SimpleChanges): void {
    if ('bgcData' in changes) {
      const files = this.bgcData?.files || '';
      this.status1 = this.bgcData?.status || '';
      this.fileUploadedStatus = files.split(',');
      this.stageId = this.bgcData?.stageId || '';
      this.taskname = this.bgcData?.taskName || '';
      // console.log(this.stageId, this.taskname);
    }
  }

  checkFile(fileToCheck: string): boolean {
    return this.fileUploadedStatus.includes(fileToCheck);
  }

  getFileSuccess(filename: string): boolean {
    const file = this.filescopy.find(file => file.file && file.file.name === filename);
    return file ? file.success : false;
  }

  onFileChange1(event: any): void {
    const files: FileList | null = event.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const fileName = files[i].name;

        if (!this.expectedFilenames.includes(fileName)) {
          // Invalid file name handling
          // this.closeModal();
          this.toastMessage.showError('Invalid file selected.')
          event.target.value = ''; // Clear the file input
          return;
        }

        // Update the success property for the respective file
        const fileIndex = this.selectedFiles.findIndex(file => file.file.name === fileName);
        if (fileIndex !== -1) {
          this.selectedFiles[fileIndex].success = true;
        } else {
          // If the file is not in the selectedFiles array, add it with success set to true
          this.selectedFiles.push({ file: files[i], success: true });
          this.updateFilescopy();
        }
      }
    }
  }

  openModal(): void {
    // Use nativeElement to access the underlying DOM element
    document.getElementById('upbtn')?.click();

  }

  // private closeModal(): void {
  //   // Use nativeElement to access the underlying DOM element
  //   // document.getElementById('idbtn')?.click();
  // }


  downloadtemplate() {
    const taskname = localStorage.getItem('taskname');
    let link;

    if (taskname === 'New Hire') {
      link = 'assets/newhire.zip';
    } else if (taskname === 'Internal Movement') {
      link = 'assets/BGC_Template.zip';
    }
    else {
      link = 'assets/BGC_Templates.zip';
    }

    fetch(link)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = taskname === 'New Hire' ? 'newhire.zip' : 'BGC_Template.zip'; // Set the file name based on the task
        document.body.appendChild(a); // Append the link to the body
        a.click();
        a.remove(); // Remove the link after download
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Download failed:', error);
      });
  }
  //upload files
  uploadFiles(): void {
    this.loaderService.loading.next(true);

    // Start with the first file (index 0)
    this.uploadFileAtIndex(0);
  }

  uploadFileAtIndex(index: number): void {
    if (index >= this.selectedFiles.length) {
      // All files have been processed
      this.loaderService.loading.next(false);
      console.log(index, this.selectedFiles.length);
      this.bgcService.fatchBGC(this.bgcData?.processId).subscribe((response: any) => {
        if (response.status == "success") {
          this.loaderService.loading.next(true);
          this.status1 = response.data.status;
          // this.stageId = this.status1.stageId;
          //this.loaderService.loading.next(false);
          if (this.status1 == "UPLOADED" || this.status1 == "VALIDATED") {
            this.validatebgc(this.bgcData?.processId, "validate");
          }
          else {
            this.loaderService.loading.next(false);
          }
        }
        else {
          this.loaderService.loading.next(false);
          //this.toastMessage.showError(response.error_message);
        }
      },
        (error) => {
          console.log(error);
          this.loaderService.loading.next(false);
          //this.toastMessage.showError(error.error_message);
        }
      );

      return;

    }

    const fileItem = this.selectedFiles[index];
    const file = fileItem.file;

    // Call uploadFile to get the URL from the backend
    this.uploadFile(file)
      .subscribe((response: any) => {
        if (response.status === "success") {
          const url = response.url;

          // Once we have the URL, upload the file to that URL
          this.uploadToUrl(file, url)
            .subscribe({
              next: (response: any) => {
                if (response.status === 200) {
                  // Handle successful upload
                  this.toastMessage.showSuccess(`${file.name} uploaded successfully.`);
                  fileItem.success = true; // Update success status of the file

                  // Remove the file from the selectedFiles array
                  //  this.validatebgc(this.bgcData?.processId,"validate",file.name);

                  this.selectedFiles.splice(index, 1);

                  // Proceed to the next file
                  this.uploadFileAtIndex(index);
                  // No need to increment index since we removed the file
                } else {
                  // Handle upload failure
                  this.toastMessage.showSuccess(`${file.name} uploaded successfully.`);

                  // this.toastMessage.showError(`Failed to upload ${file.name}. Status code: ${response.status}`);
                  // Proceed to the next file
                  this.uploadFileAtIndex(index + 1);
                }
              },
              error: (error: any) => {
                // Handle error
                this.toastMessage.showError(`Error uploading file: ${error.message}`);

                // Proceed to the next file
                this.uploadFileAtIndex(index + 1);
              }
            });
        } else {
          // Handle error getting URL
          this.toastMessage.showError(`Failed to get URL for ${file.name}. Status code: ${response.status}`);

          // Proceed to the next file
          this.uploadFileAtIndex(index + 1);
        }
      });

  }



  // uploade files

  //   uploadFiles(): void {

  //     this.loaderService.loading.next(true);
  //     // Ensure there are files to upload
  //     if (this.selectedFiles.length === 0) {
  //       console.log('No files to upload.');
  //       return;
  //     }

  //     from(this.selectedFiles).pipe(
  //       concatMap(file => this.uploadFile(file.file)),

  //       finalize(() => {
  //         // This block will execute once all files are uploaded
  //         this.loaderService.loading.next(true);
  //        // this.toastMessage.showSuccess('All files uploaded successfully.');
  //       })
  //     ).subscribe({
  //       next: (response: any) => {
  //         // this.loaderService.loading.next(false);
  //        // this.selectedFiles = [];
  //         if (response.status == "success") {
  //          // this.toastMessage.showSuccess(response?.message);
  //           // Extract text after the 5th '/' and before the 1st '?'
  //           this.fileurl=response?.url;
  //           const extractedText = response?.url.split('/').slice(5).join('/').split('?')[0];

  // // Extract the filename directly from the extracted text
  //           const efilename = extractedText.split('/').pop();
  //      this.toastMessage.showSuccess(efilename +' '+ response?.message);
  //           //refresh the page
  //           // window.location.reload();
  //           //this.selectedFiles=[];
  //           this.uploadFilesToUrl(efilename);
  //         //  this.selectedFiles = this.selectedFiles.filter(file => file.success !== true);
  //           // this.selectedFiles.push({ file: response?.url, success: true });
  //           // this.getFileSuccess(response?.url);
  //           // this.checkFile(response?.url);
  //         //   this.getFileSuccess(response?.url);



  //         } else {
  //           this.toastMessage.showError(response?.error_message)
  //         }
  //       },
  //       error: (error: any) => {
  //         this.loaderService.loading.next(false);
  //         this.toastMessage.showError("Error uploading file:"+error?.error?.error_message)
  //       }
  //     }
  //     );
  //   }
  updateFilescopy(): void {
    // Concatenate the existing filescopy with the newly added files from selectedFiles
    this.filescopy = [...this.filescopy, ...this.selectedFiles];
  }



  uploadFile(file: File): Observable<any> {
    console.log('file', file)
    const payload = {
      'filename': file.name,
    }
    console.log('filename', file.name);

    // Replace 'uploadUrl' with your actual endpoint
    return this.bgcService.uploadnew(file.name, this.bgcData?.processId);

  }

  uploadFilesToUrl(fname: any): void {
    this.loaderService.loading.next(true);

    from(this.selectedFiles).pipe(
      concatMap(file => this.uploadToUrl(file.file, this.fileurl)),
      finalize(() => {
        //this.loaderService.loading.next(true);

      })
    ).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.toastMessage.showSuccess(fname + ' ' + "File uploaded successfully.");
          this.loaderService.loading.next(false);
          this.selectedFiles = this.selectedFiles.filter(file => file.success !== true);
        } else {
          console.log("Failed to upload file. Status code: " + response.status);
          this.loaderService.loading.next(false);
        }
      },
      error: (error: any) => {
        this.loaderService.loading.next(false);
        console.log("Error uploading file:" + error.message);
      }
    });
  }

  //
  uploadToUrl(file: File, url: string): Observable<any> {
    console.log('file', file);
    const contentType = file.type;

    // Notify the user the file is being uploaded
    this.toastMessage.showmessage(`${file.name} File is being uploaded`);

    // Directly call the fileurl method with the necessary arguments
    return this.bgcService.fileurl(url, contentType, file);
  }

  //
  validatebgc(pid: any, mode: string) {
    // this.loaderService.loading.next(true);
    this.loaderService.loading.next(true);
    this.toastMessage.showmessage("Validating file");
    const payload = {
      "mode": mode,    //mode:"start/stop/delete"
      "stageId": this.stageId,
    }

    this.bgcService.bgcManage(payload, pid).subscribe((response: any) => {

      if (response.status == "success") {

        this.toastMessage.showSuccess(response.message);
        this.loaderService.loading.next(false);
      }
      else {

        this.toastMessage.showError(response.message);
        //this.manageBGC(pid,"start");
        this.loaderService.loading.next(false);
      }
    }, (error) => {
      this.loaderService.loading.next(false);
      //this.toastMessage.showWarning(error?.error?.error);
      // this.toastMessage.showError(error?.error?.error_message);
      //this.manageBGC(pid,"start");
    });
  }
}
