import { LoaderService } from 'src/app/shared/service/loader.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SopService } from '../shared/service/sop.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  cardsData: any[] = [
    { title: 'Audit Compliance', description: 'Description for Card 3', link: 'https://genpactdevelop.service-now.com/ai' },
    { title: 'Document Summary', description: 'Description for Card 5', link: '/document-summary'},
    { title: 'Meeting Assist', description: 'Description for Card 4', link: '/meeting-assist' },
    { title: 'SOC Report', description: 'Description for Card 4', link: '/soc-report' },
    { title: 'BGC Validation', description: 'Description for Card 4', link: '/bgc' },
    { title: 'Sales Assist', description: 'Description for Card 5', link: '/g-chat'},
    { title: 'Konverse AI', description: 'Description for Card 6', link: '/konverse-questions'},
    { title: 'Konverse IT Solutions', description: 'Description for Card 7', link: '/konverseitsolutions'},
  ];
  currentPage: number = 1;
  itemsPerPage: number = 3;
  paginatedData: any[][] = [];
  botsArray: any[] = [];

  constructor(private router: Router, private sopService: SopService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    // this.itemCheck(); 
    this.updatePagination();
  }

  updatePagination() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.cardsData.length);
    this.paginatedData = [];
    for (let i = startIndex; i < endIndex; i += 3) {
      this.paginatedData.push(this.cardsData.slice(i, i + 3));
    }
  }

  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updatePagination();
  }

  onPreviousPage() {
    if (this.currentPage > 1) {
      this.onPageChange(this.currentPage - 1);
    }
  }

  onNextPage() {
    if (this.currentPage < Math.ceil(this.cardsData.length / this.itemsPerPage)) {
      this.onPageChange(this.currentPage + 1);
    }
  }

  redirectToLink(card: any) {
    if (card.title.toLowerCase() === 'audit compliance') {
      // Open in a new tab
      window.open('https://genpactdevelop.service-now.com/ai', '_blank');
    } else {
      // Navigate using Angular Router
      this.router.navigate([card.link]);
    }
  }

  get totalPages(): number {
    return Math.ceil(this.cardsData.length / this.itemsPerPage);
  }

  get totalPagesArray(): any[] {
    return Array(this.totalPages).fill(0);
  }

  // itemCheck() {
  //   this.sopService.getAccess().subscribe((res: any) => {
  //     console.log(res);
  //    this.botsArray = res.response?.data?.roles['0'].map((item: any) => item.name);
  //    if(this.botsArray.includes('SOC')) {
  //     this.cardsData.push({ title: 'SOC\nReport', description: 'Description for Card 1', link: '/soc-report' });
  //   }
  //   if(this.botsArray.includes('BGC')) {
  //     this.cardsData.push({ title: 'BGC Validation', description: 'Description for Card 2', link: '/bgc' });
  //   }
  //   this.cardsData.push
  //   (    
  //     { title: 'Audit Compliance', description: 'Description for Card 3', link: 'https://genpactdevelop.service-now.com/ai' },
  //     { title: 'Meeting Assist', description: 'Description for Card 4', link: '/meeting-assist' },
  //     { title: 'Document Summary', description: 'Description for Card 5', link: '/document-summary'}
  //   )
  //   this.onPageChange(1);
  //   });
  //   console.log(this.cardsData);
  // }
  
}
