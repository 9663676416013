<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<app-sidenav ></app-sidenav>

<div class="row">
<div class="col-md-2 col-sm-2">
<div class="left-nav">
    <ul>
      <li>
        <a><b class="white-text">Dashboard</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-questions"><b class="white-text">Questions Library</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-adhoc"><b class="white-text">Adhoc</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-bulk"><b class="white-text">Bulk Upload</b></a>        
      </li>
    </ul>
  </div>
</div>
<div class="col-md-10 col-sm-10">
  <mat-tab-group>
    <mat-tab label="Predefined Questions">
        <br><br>
        <div class="container mt-3">
            <span class="d-flex w-50 align-items-center">
                <select class="custom-select" id="basic">
                  <option value="All" selected>Select All</option>
                  <option *ngFor="let client of clientItems" [value]="client.customer">{{client.customer}}</option>                    
                </select>  
                &nbsp;&nbsp;      
                <button type="button" class="btn btn-primary">Filter</button>
            </span> 
        </div>          
        <br><br>        
        <table style="margin: 0 auto; width:100%">
            <thead>
              <tr>
                <th><input class="form-check-input" style="margin: 5px auto" type="checkbox" value="" id="defaultCheck1">&nbsp;&nbsp;</th>
                <th>S.No.</th>
                <th>Questions</th>
                <th>Tags</th>                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items">
                <td><input class="form-check-input" style="margin: -5px auto" type="checkbox" value="" id="defaultCheck1"></td>
                <td>{{item.number}}</td>
                <td>{{item.questions}}</td>
                <td>{{item.tags}}</td>                
              </tr>
            </tbody>
          </table>
          <br><br>
          <div class="col-md-12 col-sm-12">
            <mat-tab-group>              
              <mat-tab label="Bulk Upload">
                <div class="container mt-3">
                  <form class="d-flex w-100 align-items-center">
                      <!-- File Input -->
                      <input type="file" id="file-input" name="file-input" class="form-control me-2 flex-grow-1">
              
                      <!-- Search Button -->
                      <button type='submit' class='btn btn-primary'>Search</button>
                  </form> 
              </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <br><br>
          <table style="margin: 0 auto; width:100%;border: 2px solid black;">
            <thead>
              <tr>                
                <th class="border">S.No.</th>
                <th class="border">Prompt</th>
                <th class="border">Documents Context</th>
                <th class="border">Consolidated output</th>
                <th class="border">Conservative output</th>
                <th class="border">Confidence Score(%)</th>
              </tr>
            </thead>
            <tbody>
              <!--<tr *ngFor="let item of items">-->                
              <tr>
                <td class="border">1</td>
                <td class="border">How does the “business” connect to client network ?</td>
                <td class="border">solution/Genpact Core Technology Solution Document for Facebook Community operations.pdf</td>
                <td class="border">1. According to the solution document, Genpact connects to Facebook's network over the open internet using redundant internet links (Section 4.1.1)</td>
                <td class="border">Summarized Key Points:
                    1. Genpact connects to Facebook's network over the open internet using redundant internet links.
                    2. Genpact does not have direct connectivity to Facebook's private cloud.
                    3……
                </td>
                <td class="border">90%</td>            
              </tr>
              <tr>
                <td class="border">2</td>
                <td class="border">How does the “business” connect to client network ?</td>
                <td class="border">solution/Genpact Core Technology Solution Document for Facebook Community operations.pdf</td>
                <td class="border">1. According to the solution document, Genpact connects to Facebook's network over the open internet using redundant internet links (Section 4.1.1)</td>
                <td class="border">Summarized Key Points:
                    1. Genpact connects to Facebook's network over the open internet using redundant internet links.
                    2. Genpact does not have direct connectivity to Facebook's private cloud.
                    3……
                </td>
                <td class="border">90%</td>            
              </tr>
              <tr>
                <td class="border">3</td>
                <td class="border">How does the “business” connect to client network ?</td>
                <td class="border">solution/Genpact Core Technology Solution Document for Facebook Community operations.pdf</td>
                <td class="border">1. According to the solution document, Genpact connects to Facebook's network over the open internet using redundant internet links (Section 4.1.1)</td>
                <td class="border">Summarized Key Points:
                    1. Genpact connects to Facebook's network over the open internet using redundant internet links.
                    2. Genpact does not have direct connectivity to Facebook's private cloud.
                    3……
                </td>
                <td class="border">90%</td>            
              </tr>
            </tbody>
          </table>
    </mat-tab>
    <mat-tab label="Possible Questions of Documents">
        <br><br>
        <div class="container mt-3">
            <span class="d-flex w-50 align-items-center">
                <select class="custom-select" id="basic">
                  <option value="All" selected>Select All</option>
                  <option *ngFor="let client of clientItems" [value]="client.customer">{{client.customer}}</option>                    
                </select>  
                &nbsp;&nbsp;      
                <button type="button" class="btn btn-primary" (click)="onSearch('')">Filter</button>
            </span> 
        </div>          
        <br><br>        
        <table style="margin: 0 auto; width:100%">
            <thead>
              <tr>
                <th><input class="form-check-input" style="margin: 5px auto" type="checkbox" value="" id="defaultCheck1">&nbsp;&nbsp;</th>
                <th>S.No.</th>
                <th>Questions</th>
                <th>Tags</th>                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of possibleItems">
                <td><input class="form-check-input" style="margin: -5px auto" type="checkbox" value="" id="defaultCheck1"></td>
                <td>{{item.number}}</td>
                <td>{{item.questions}}</td>
                <td>{{item.tags}}</td>                
              </tr>
            </tbody>
          </table>
          <br><br>
          <div class="col-md-12 col-sm-12">
            <mat-tab-group>              
              <mat-tab label="Bulk Upload">
                <div class="container mt-3">
                  <form class="d-flex w-100 align-items-center">
                      <!-- File Input -->
                      <input type="file" id="file-input" name="file-input" class="form-control me-2 flex-grow-1">
              
                      <!-- Search Button -->
                      <button type='submit' class='btn btn-primary'>Search</button>
                  </form> 
              </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <br><br>
          <table style="margin: 0 auto; width:100%;border: 2px solid black;">
            <thead>
              <tr>                
                <th class="border">S.No.</th>
                <th class="border">Prompt</th>
                <th class="border">Documents Context</th>
                <th class="border">Consolidated output</th>
                <th class="border">Conservative output</th>
                <th class="border">Confidence Score(%)</th>
              </tr>
            </thead>
            <tbody>
              <!--<tr *ngFor="let item of items">-->                
              <tr>
                <td class="border">1</td>
                <td class="border">How does the “business” connect to client network ?</td>
                <td class="border">solution/Genpact Core Technology Solution Document for Facebook Community operations.pdf</td>
                <td class="border">1. According to the solution document, Genpact connects to Facebook's network over the open internet using redundant internet links (Section 4.1.1)</td>
                <td class="border">Summarized Key Points:
                    1. Genpact connects to Facebook's network over the open internet using redundant internet links.
                    2. Genpact does not have direct connectivity to Facebook's private cloud.
                    3……
                </td>
                <td class="border">90%</td>            
              </tr>
              <tr>
                <td class="border">2</td>
                <td class="border">How does the “business” connect to client network ?</td>
                <td class="border">solution/Genpact Core Technology Solution Document for Facebook Community operations.pdf</td>
                <td class="border">1. According to the solution document, Genpact connects to Facebook's network over the open internet using redundant internet links (Section 4.1.1)</td>
                <td class="border">Summarized Key Points:
                    1. Genpact connects to Facebook's network over the open internet using redundant internet links.
                    2. Genpact does not have direct connectivity to Facebook's private cloud.
                    3……
                </td>
                <td class="border">90%</td>            
              </tr>
              <tr>
                <td class="border">3</td>
                <td class="border">How does the “business” connect to client network ?</td>
                <td class="border">solution/Genpact Core Technology Solution Document for Facebook Community operations.pdf</td>
                <td class="border">1. According to the solution document, Genpact connects to Facebook's network over the open internet using redundant internet links (Section 4.1.1)</td>
                <td class="border">Summarized Key Points:
                    1. Genpact connects to Facebook's network over the open internet using redundant internet links.
                    2. Genpact does not have direct connectivity to Facebook's private cloud.
                    3……
                </td>
                <td class="border">90%</td>            
              </tr>
            </tbody>
          </table>
    </mat-tab>
    <mat-tab label="Control Deviation Report">
      Content for Tab 3
    </mat-tab>
  </mat-tab-group>
</div>
</div>
