//import { Component } from '@angular/core';
import { Component } from '@angular/core';

interface NavigationItem {
  title: string;
  children: NavigationItem[];
  collapsed: boolean; // Add the 'collapsed' property
}

@Component({
  selector: 'app-konverse-ai',
  templateUrl: './konverse-ai.component.html',
  styleUrls: ['./konverse-ai.component.scss']
})
export class KonverseAIComponent {

  navigationItems: NavigationItem[] = [
    {
      title: 'Dashboard',
      children: [],
      collapsed: true // Set the initial state
    },
    {
      title: 'IT Compliance',
      children: [
        { title: 'Questions Management', children: [], collapsed: true },
        { title: 'Possible Questions of Documents', children: [], collapsed: true },
        { title: 'Control Deviation Report', children: [], collapsed: true }
      ],
      collapsed: true // Set the initial state
    },
    {
      title: 'History',
      children: [],
      collapsed: true // Set the initial state
    },
    {
      title: 'Audit Logs',
      children: [],
      collapsed: true // Set the initial state
    }
  ];

  toggleItem(item: NavigationItem): void {
    item.collapsed = !item.collapsed;
  }

  items = [
    { number: 1, questions: 'What is artificial intelligence (AI)?', action: '' },
    { number: 2, questions: 'What are the different types of AI?', action: '' },
    { number: 3, questions: 'How does AI work?', action: '' },
    { number: 4, questions: 'How can I benefit from AI?', action: '' },
    { number: 5, questions: 'What kind of people work with AI?', action: '' }
  ];

  showQuestions() {
    // Add your logic here to display the questions or perform any action
    console.log("Questions button clicked");
  }
}
