import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BgcService {
  //endpoint = 'https://gapiw.playground-poc.viren.in/bgc';
  // endpoint = 'https://gapiw.playground-uat.genpact.com/bgc';
  // endpoint=environment.bgcURL;
  endpoint = 'https://gapiw.playground-poc.viren.in/bgc';
  //endpoint ='https://gapiw.playground-dev.genpact.com/bgcheck';
  endpoint1 = 'https://m3po2fpqgj.execute-api.ap-south-1.amazonaws.com/dev';
  accesspoint = environment.nodeEndpoint;


  constructor(private http: HttpClient,) { }
  // getDataAsPromise(url: string, data: any = { method: 'GET' }): Promise<any> {
  //   let token: any = localStorage.getItem('token');
  //   return new Promise((resolve, reject) => {
  //     fetch(url, data)
  //       .then(response => response.json())
  //       .then(result => resolve(result))
  //       .catch(error => resolve(error));
  //   });
  // }
  useraccesscheck(): Observable<any> {
    //http post request with payload
    return this.http.post(`${this.accesspoint}validate-role`, {
      'process_value': "BGC",
    });

  }
  getDataAsPromise(url: any, data: any = { method: 'GET', mode: 'no-cors' }, headers: any = {}): Promise<any> {
    // let token: any = localStorage.getItem('token');
    headers = {

      //     //Authorization: 'Bearer ' + token,
      'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     // 'Authetication': 'Bearer ' + token
      //   //  'Access-Control-Allow-Credentials': true
    };
    return new Promise((resolve, reject) => {
      fetch(url, { ...data, headers })
        .then(response => {
          return resolve({
            status: response.status,
            statusText: response.statusText
          });
        })
        .catch(error => resolve(error));
    });
  }
  // getDataAsPromise1(url: string, data: any = { method: 'GET' }, headers: any = {}): Promise<any> {
  //   let token: any = localStorage.getItem('token');

  //   // Add authorization header with bearer token
  //   headers = {

  //     Authorization: 'Bearer ' + token,
  //     'Content-Type': 'application/json',
  //     // 'Authetication': 'Bearer ' + token
  //   //  'Access-Control-Allow-Credentials': true
  //   };
  //   // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   // return this.http.post(`${this.imageGeneratorURL}/image-generate`, paylaod, { headers });

  //   return new Promise((resolve, reject) => {
  //     fetch(url, { ...data, headers })
  //       .then(response => response.json())
  //       .then(result => resolve(result))
  //       .catch(error => resolve(error));
  //   });
  // }
  // getDataAsPromise2(url: string, data: any = { method: 'GET' }): Promise<any> {
  //   let token: any = localStorage.getItem('token');
  //   return new Promise((resolve, reject) => {
  //     fetch(url, data)
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! Status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then(result => resolve(result))
  //       .catch(error => reject(error));
  //   });
  // }
  // getDataAsPromisePost(url: string, formData: FormData): Promise<any> {
  //   let token: any = localStorage.getItem('token');

  //   const headers = new Headers({
  //     'Authorization': 'Bearer ' + token,
  //     //'Content-type': 'application/json'
  //   });

  //   return new Promise((resolve, reject) => {
  //     fetch(url, {
  //       method: 'POST',
  //       body: formData,
  //       headers: headers,
  //     })
  //       .then(response => response.json())
  //       .then(result => resolve(result))
  //       .catch(error => reject(error));
  //   });
  // }


  // getDataAsPromise2(url: string, data: any = { method: 'GET' }, headers: any = {}): Promise<any> {
  //   let token: any = localStorage.getItem('token');

  //   // Add authorization header with bearer token
  //   headers = {
  //     ...headers,
  //     Authorization: 'Bearer ' + token,
  //     'Content-Type': 'application/json'
  //   };

  //   const axiosConfig: AxiosRequestConfig = {
  //     method: data.method || 'GET',
  //     url: url,
  //     headers: headers,
  //     data: data.body
  //   };

  //   return axios(axiosConfig)
  //     .then((response: AxiosResponse) => response.data)
  //     .catch((error: any) => {
  //       throw error.response ? error.response.data : error;
  //     });
  // }

  bgchealthcheck(): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/welcome`, { method: 'GET' }); // Calling your method that returns a Promise
    // return from(promise);
    return this.http.get(`${this.endpoint}/welcome`);
  }

  bgcupload(formData: FormData, pid: any): Observable<any> {
    //let token: any = localStorage.getItem('token');

    // const promise = this.getDataAsPromisePost(`${this.endpoint}/process/${pid}/upload`, formData); // Calling your method that returns a Promise
    // return from(promise);

    return this.http.post(`${this.endpoint}/process/${pid}/upload`, formData);
  }



  bgcinitiate(payload: any): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/init`, { method: 'POST', body: JSON.stringify(payload) });
    // return from(promise);
    return this.http.post(`${this.endpoint}/process/init`, payload);
  }

  fatchBGC(id: any): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/${id}`, { method: 'GET' });
    // return from(promise);
    return this.http.get(`${this.endpoint}/process/${id}`);
  }

  bgclist(): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/list`, { method: 'GET' }); // Calling your method that returns a Promise
    // return from(promise);
    // let token: any = localStorage.getItem('token');
    // const headers = new HttpHeaders({
    //   'Authorization': 'Bearer ' + token,
    //   'Accept': 'application/json'
    // });
    return this.http.get(`${this.endpoint}/process/list`);
  }

  bgcManage(formData: any, pid: any): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/${pid}/manage`, { method: 'POST', body: JSON.stringify(formData) }); // Calling your method that returns a Promise
    // return from(promise);
    return this.http.post(`${this.endpoint}/process/${pid}/manage`, formData);
  }
  bgcdownload(formData: any, pid: any): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/${pid}/export`, { method: 'POST', body: JSON.stringify(formData) }); // Calling your method that returns a Promise
    // return from(promise);
    return this.http.post(`${this.endpoint}/process/${pid}/export`, formData);
  }

  deleteProcess(pid: any): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/${pid}`, { method: 'DELETE' }); // Calling your method that returns a Promise
    // return from(promise);
    return this.http.delete(`${this.endpoint}/process/${pid}`);
  }

  uploadnew(filename: any, pid: any): Observable<any> {
    // const promise = this.getDataAsPromise1(`${this.endpoint}/process/${pid}/upload`, { method: 'POST', body: JSON.stringify(formData) }); // Calling your method that returns a Promise
    // return from(promise);
    // let token = localStorage.getItem('token');
    // const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(`${this.endpoint}/process/${pid}/upload?filename=${filename}`, filename);
  }

  // fileurl(fileUploadUrl: any, contenttype: any, file: any) {
  //   // const headersWithoutToken = new HttpHeaders();     // Make the HTTP request without the token in the headers
  //   // return this.http.get<any>('https://your-api-url.com/data',
  //   // { headers: headersWithoutToken });
  //   const headers = new HttpHeaders({ 'Content-Type': contenttype,
  // 'Authorization': 'NoAuth' });
  //   const req = new HttpRequest(
  //     'PUT',
  //     fileUploadUrl,
  //     file,
  //     {
  //       headers: headers,
  //     }
  //     );
  //   return this.http.request(req);
  // }

  fileurl(fileUploadUrl: string, contentType: string, file: File): Observable<any> {
    // Create the headers with the content type
    const headers = new Headers({
      'Content-Type': contentType
    });

    // Create the fetch request options for the PUT request
    const requestOptions = {
      method: 'PUT',
      headers: headers,
      body: file // Send the file directly as the body
    };

    // Make the fetch call and return an Observable from the promise
    const promise = fetch(fileUploadUrl, requestOptions)
      .then(response => response.text()) // or response.json(), depending on the response type
      .catch(error => {
        console.error('File upload error:', error);
        throw error;
      });

    return from(promise);
  }



}
