<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<app-sidenav ></app-sidenav>

<div class="row">
<div class="col-md-2 col-sm-2">
<div class="left-nav">
    <ul>
      <li>
        <a><b class="white-text">Dashboard</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-questions"><b class="white-text">Questions Library</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-adhoc"><b class="white-text">Adhoc</b></a>        
      </li>
      <li>
        <a routerLink="/konverse-bulk"><b class="white-text">Bulk Upload</b></a>        
      </li>
    </ul>
  </div>
</div>
<div class="col-md-10 col-sm-10">
  <mat-tab-group>
    <mat-tab label="Questions Library">
        <br><br>
        <button type="button" class="btn btn-primary" disabled="disabled">Add Questions</button>
        <br><br>
        <!--<button (click)="showQuestions()">Questions</button>-->
        <table style="margin: 0 auto; width:100%">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Questions</th>
                <th>Tags</th> 
                <th>Action</th>                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of items">
                <td>{{item.number}}</td>
                <td>{{item.questions}}</td>
                <td>{{item.tags}}</td> 
                <td><i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;&nbsp;{{item.action}}<i class="fa fa-trash-o" aria-hidden="true"></i></td>
              </tr>
            </tbody>
          </table>          
    </mat-tab>
  </mat-tab-group>
</div>
</div>