import { Component } from '@angular/core';

@Component({
  selector: 'app-konverseitsolutions',
  templateUrl: './konverseitsolutions.component.html',
  styleUrls: ['./konverseitsolutions.component.scss']
})
export class KonverseitsolutionsComponent {

}
