import { Component } from '@angular/core';
import {FormControl} from '@angular/forms';

// src/app/components/search/search.component.ts
import { KendraService } from '../services/kendra.service';
import { GetclientsService } from '../services/getclients.service';

interface NavigationItem {
  title: string;
  children: NavigationItem[];
  collapsed: boolean; // Add the 'collapsed' property
}

@Component({
  selector: 'app-konverse-bulk',
  templateUrl: './konverse-bulk.component.html',
  styleUrls: ['./konverse-bulk.component.scss']
})
export class KonverseBulkComponent {

  results: any[] = [];
  questionsAll = "";
  clientItems: any[] = [];

  constructor(
    private kendraService: KendraService,
    private GetclientsService: GetclientsService
  ) {}
  

  ngOnInit() {
    this.onGetClients('');
  }

  onGetClients(queryText: string) {
    this.GetclientsService.getAllClients(queryText).subscribe({
      next: (data: any) => {     
          const allClients = data.clientName;  
          this.clientItems = [];
          for (let i = 0; i < allClients.length; i++) {
            this.clientItems.push({ customer: allClients[i] },);              
          }
      },
      error: (error: any) => {
          console.log(error)
      },
      complete: () => {
          console.log('complete')
      }
    });
  }

  /*onSearch(queryText: string) {
  this.kendraService.search(queryText).subscribe({
    next: (data: string) => {
        this.questionsAll = data;
    },
    error: (error: any) => {
        console.log(error)
    },
    complete: () => {
        console.log('complete')
    }
  });
}   */  

  //onSearch(queryText: string) {
  onSearch(queryText: string) {
    this.kendraService.search(queryText).then((data) => {
      this.results = data.possiblequestions;
      this.questionsAll = JSON.parse(data.possiblequestions);
      this.showQuestions();
    }).catch((error) => {
      console.error('Error searching:', error);
    });
  }

  /*onGetClients(queryText: string) {
    this.GetclientsService.getAllClients(queryText).then((data) => {
      this.results = data.possiblequestions;
      this.questionsAll = JSON.parse(data.possiblequestions);
      this.showQuestions();
    }).catch((error) => {
      console.error('Error searching:', error);
    });
  }*/

  navigationItems: NavigationItem[] = [
    {
      title: 'Dashboard',
      children: [],
      collapsed: true // Set the initial state
    },
    {
      title: 'IT Compliance',
      children: [
        { title: 'Questions Management', children: [], collapsed: true },
        { title: 'Possible Questions of Documents', children: [], collapsed: true },
        { title: 'Control Deviation Report', children: [], collapsed: true }
      ],
      collapsed: true // Set the initial state
    },
    {
      title: 'History',
      children: [],
      collapsed: true // Set the initial state
    },
    {
      title: 'Audit Logs',
      children: [],
      collapsed: true // Set the initial state
    }
  ];

  toggleItem(item: NavigationItem): void {
    item.collapsed = !item.collapsed;
  }

  items = [
    { number: 1, questions: 'What is artificial intelligence (AI)?', tags: 'Tag 1', action: '' },
    { number: 2, questions: 'What are the different types of AI?', tags: 'Tag 2', action: '' },
    { number: 3, questions: 'How does AI work?', tags: 'Tag 3', action: '' },
    { number: 4, questions: 'How can I benefit from AI?', tags: 'Tag 4', action: '' },
    { number: 5, questions: 'What kind of people work with AI?', tags: 'Tag 5', action: '' }
  ];

  possibleItems: any[] = [];

  showQuestions() {
    // Add your logic here to display the questions or perform any action
    console.log("Questions button clicked");
    this.possibleItems = [];
    for (let i = 0; i < this.questionsAll.length; i++) {
      this.possibleItems.push({ number: (i+1), questions: this.questionsAll[i], tags: 'Tag' + (i+1), action: '' },);              
    }
  }
}
