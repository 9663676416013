import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MeetingNotesService {

  socApi = environment.apiURL + '/konverse_sfdc';
  // socApi = 'https://api.playground-uat.genpact.com/konverse_sfdc';
  accesspoint = environment.nodeEndpoint;

  userslistUrl = 'https://genpactonline.sharepoint.com/sites/presales_km_uat/_api/web/siteusers';

  constructor(private http: HttpClient) { }

  userAccesscheck(): Observable<any> {
    //http post request with payload
    return this.http.post(`${this.accesspoint}validate-role`, {
      'process_value': "GKnowledge",
    });

  }

  getAccessToken(): Observable<any> {
    return this.http.get(`${this.socApi}/get-gknowledge-token`);
  }

  getUserData(AccessToken: string): Observable<any> {
    const headers = {
      Authorization: `Bearer ${AccessToken}`,
      Accept: 'application/json;odata=verbose',
    };

    // Return an Observable that wraps the fetch API
    return new Observable((observer) => {
      fetch(this.userslistUrl, {
        method: 'GET',
        headers: headers,
      })
        .then((response) => {
          if (!response.ok) {
            // Handle HTTP errors
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json(); // Parse the JSON response
        })
        .then((data) => {
          observer.next(data); // Emit the parsed data
          observer.complete(); // Mark Observable as complete
        })
        .catch((error) => {
          observer.error(error); // Emit the error
        });
    });
  }


  getAccount() {
    return this.http.get(`${this.socApi}/accounts`);
  }

  getBuyingCenters(payload: any) {
    return this.http.post(`${this.socApi}/buying-centers`, payload);
  }

  getMeetingNotes(payload: any): Observable<any> {
    return this.http.post(`${this.socApi}/meeting-notes`, payload);
  }

  meetingNotesStatus(payload: any): Observable<any> {
    return this.http.post(`${this.socApi}/meeting-notes-status`, payload);
  }

  sendFeedback(payload: any): Observable<any> {
    return this.http.post(`${this.socApi}/feedback`, payload);
  }

  oppurtunityChat(payload: any) {
    // return this.http.post(`${this.socApi}/opportunities`, payload);
    return this.http.post('https://api.playground-uat.genpact.com/konverse_sfdc/opportunities', payload);
  }

}

