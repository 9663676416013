<div class="px-md-5">
    <div class="item px-md-5" >
        <form data-validation="true" enctype="multipart/form-data">
            <div class="item-inner">
                <div class="item-content p-0 p-md-0 d-flex justify-content-center">
                    <div class="image-upload" [ngClass]="{'disabled':!bgcData?.processId}">
                        <label class="label1" style="cursor: pointer;" for="file_upload">
                            <img src="" alt="" class="uploaded-image">
                            <div class="h-100">
                                <div class="dplay-tbl">
                                    <div class="dplay-tbl-cell text-left pl-3">
                                        <h6 style="color:#073262"><b>Choose your file(s) to start validation
                                                batch</b></h6>
                                        <p style="color: #00AECF; "><i>{{ placeholder }}</i> </p>
                                    </div>
                                </div>
                            </div>
                            <input type="file" class="form-control" id="file_upload" style="display: none;"
                                (change)="onFileChange1($event)" accept=".xlsx" multiple />
                            <div class="col-md-12">

                                <!-- Assuming this is inside the ngFor loop that iterates over expectedFilenames -->
                                <div class="dpnames" *ngFor="let filename of getExpectedFilenames(); let i = index">
                                    <span [class.invalid]="!getFileSuccess(filename)" style="font-size: 12px;">
                                        {{ i + 1 }}. <b style="padding-left: 2px;">{{ filename }}</b> : - {{ getFileDescription(filename) }}
                                      <span *ngIf="getFileSuccess(filename) || checkFile(filename)">
                                        <i style="color: #1BA641">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022" />
                                          </svg>
                                        </i>
                                      </span>
                                    </span>
                                  </div>

                            </div>
                        </label>
                    </div>
                    <br>
                </div>
                <div class="d-grid gap-2 d-sm-flex p-3 justify-content-center">
                    <button style="font-size:12px" type="button" class="btn btn-primary bgbutton" (click)="downloadtemplate()">
                      Download  template
                        <i class="fa fa-download" aria-hidden="true"></i>
                    </button>

                    <button  type="button" class="btn btn-primary" (click)="uploadFiles()"
                        [disabled]="selectedFiles.length == 0" style="font-family: georgia;font-size:12px;">Upload &
                        process
                        <i class="fa fa-upload" aria-hidden="true"></i></button>

                    <button style="font-size:12px" type="button" class="btn btn-primary bgbutton" [routerLink]="['/bgc']">Back to list</button>
                </div>
            </div>
        </form>

    </div>

</div>


